<template>
  <component :is="currentIconComponent" v-bind="{...currentIconAttributes}" style="font-size: 14px" />
</template>
<script>
import { mapState } from 'vuex'
import SvgIcon from './SvgIcon/index.vue'

export default {
  components: {
    SvgIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['platformType']),
    currentIconComponent() {
      return 'i'
      // return this.platformType === 'console-admin' ? 'i' : 'svg-icon'
    },
    currentIconAttributes() {
      if (!this.icon) return {}
      return {
        class: `iconfont ${this.icon}`,
      }
    },
  },
}
</script>
