<template>
  <div :class="`framework-header ${theme}`">
    <component :is="currentPlatformNavs" />
    <a-divider v-if="currentPlatformNavs" type="vertical" />
    <a-dropdown :trigger="['click']">
      <a href="#" class="ant-dropdown-link">
        你好，{{ userInformation.name }}
        <a-icon type="down" />
      </a>
      <a-menu slot="overlay" @click="onClick">
        <a-menu-item key="personalMsg" v-if="platformType==='console-scrm'">
          <a href="javascript:;">个人信息</a>
        </a-menu-item>
        <a-menu-item key="password">
          <a href="javascript:;">修改密码</a>
        </a-menu-item>
        <a-menu-item key="logOut">
          <a href="javascript:;">退出登录</a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <password-modal @changePasswordSuccess="changePasswordSuccess" :show-modal.sync="showPassword" />
    <ScrmPersonalMsg :show-modal.sync="showPersonalMsg" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import passwordModal from './password.vue'
import platformEnum from '@/utils/platformEnum'
import ScrmPersonalMsg from './ScrmPersonalMsg/index.vue'

export default {
  components: {
    'password-modal': passwordModal,
    ScrmPersonalMsg,
  },
  data() {
    return {
      showPassword: false,
      showPersonalMsg: false,
    }
  },
  computed: {
    ...mapState(['userInformation', 'platformType', 'currentChosen']),
    // 当前切换的组件
    currentPlatformNavs() {
      const { navComponent } = platformEnum[this.platformType] || { navComponent: '' }
      return navComponent || ''
    },
    theme() {
      const { headerTheme } = platformEnum[this.platformType] || { headerTheme: 'dark' }
      return headerTheme || 'dark'
    },
  },
  methods: {
    ...mapActions(['getMenu']),
    ...mapMutations(['LOG_OUT']),
    async onClick({ key }) {
      if (key === 'logOut') {
        await this.$post('/api/sysmgr-web/auth/logout')
        this.LOG_OUT()
      } else if (key === 'personalMsg') {
        this.showPersonalMsg = true
      } else {
        this.showPassword = true
      }
    },
    changePasswordSuccess() {
      if (this.platformType === 'console-scrm') {
        this.onClick({ key: 'logOut' })
      }
    },
  },
}
</script>
<style lang="scss">
.ant-dropdown.ant-dropdown-placement-bottomLeft {
  z-index: 2005 !important;
}
</style>
<style lang="scss" scoped>
.framework-header {
  display: flex;
  align-items: center;
  .ant-dropdown-link {
    color: rgba(0, 0, 0, 0.65);
  }
}
.light {
  .ant-dropdown-link {
    color: #999;
  }
}
.tenant-theme {
  flex: 1;
}
</style>
