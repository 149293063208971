<template>
  <div style="height: 100%">
    <a-layout id="components-layout-demo-custom-trigger" style="height: 100%">
      <a-layout-sider
        v-if="!inWholeWhiteList"
        :class="[
          'menu-layout',
          menuCollapsed ? 'ant-layout-sider-inner' : 'ant-layout-sider-spread'
        ]"
      >
        <div class="logo">
          <!-- 分平台显示，现在only admin无oem -->
          <template v-if="platformType === 'console-admin' || platformType === 'console-scrm'">
            <div class="not-oem">
              <img :src="platformEnum[platformType].logoImg" class="logo-img" />
              <h2 v-if="!menuCollapsed">{{ platformEnum[platformType].logoName }}</h2>
            </div>
          </template>
          <template v-else>
            <img
              :src="logoUrl || logoDefaultUrl"
              class="logo-img"
              :style="{ width: menuCollapsed ? '40px' : 'auto' }"
            />
            <p v-if="!menuCollapsed">{{ oem.platformName || '' }}</p>
          </template>
        </div>
        <Menu />
      </a-layout-sider>
      <a-layout style="height: 100%;">
        <a-layout-header v-if="!inWholeWhiteList">
          <div>
            <a-icon :type="menuCollapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapsed" />
          </div>
          <Header />
        </a-layout-header>
        <a-layout-content>
          <Breadcrumb />
          <UpgradeGuide v-if="showGuide" @close="showGuide = false" />
          <slot />
        </a-layout-content>
        <a-back-top :target="scrollTarget" :visibilityHeight="10" />
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Menu from '@/components/menu.vue'
import Header from '@/components/header.vue'
import Breadcrumb from './breadcrumb.vue'
import platformEnum from '@/utils/platformEnum'
import logoDefaultUrl from '@/assets/logo.png'
import UpgradeGuide from '@/components/upgradeGuide.vue'

export default {
  props: ['loading', 'content'],
  components: {
    Menu,
    Header,
    Breadcrumb,
    UpgradeGuide,
  },
  data() {
    return {
      logoUrl: '',
      platformEnum,
      logoDefaultUrl,
      recording: false,
      showGuide: false,
    }
  },
  computed: {
    // inPartWhiteList: 显示菜单栏与header; inWholeWhiteList: 不显示菜单栏与header
    ...mapState([
      'userInformation',
      'oem',
      'platformType',
      'menuCollapsed',
      'inPartWhiteList',
      'inWholeWhiteList',
    ]),
  },
  watch: {
    $route() {
      // 路由跳转的时候回到顶部
      const element = document.getElementsByClassName('ant-layout-content')[0]
      if (element) element.scrollTop = 0
    },
  },
  mounted() {
    this.loadImage((res) => {
      this.logoUrl = res
    })
    if (this.platformType === 'console-agent') {
      const timestamp = new Date().getTime() / 1000
      const lastTime = localStorage.getItem('lastTime') - 0 || (new Date().getTime() / 1000)
      const longerThan10Days = timestamp - lastTime > 86400 * 10
      this.showGuide = longerThan10Days || (timestamp - lastTime === 0)
      localStorage.setItem('lastTime', (new Date().getTime() / 1000))
    }
  },
  methods: {
    ...mapMutations(['updateState']),
    loadImage(callback) {
      const that = this
      const img = new Image()
      const url = `/api/sysmgr-web/file/oem-agent-scan?targetType=oem_logo&targetExt=${window.location.host}&zoomImage=false`
      img.src = url
      img.onload = () => {
        if (img.width !== 0) {
          callback(url)
        } else {
          callback(that.logoDefaultUrl)
        }
      }
      img.onError = () => {
        callback(that.logoDefaultUrl)
      }
    },
    // 收缩菜单
    toggleCollapsed() {
      this.updateState({ name: 'menuCollapsed', value: !this.menuCollapsed })
    },
    scrollTarget() {
      return document.getElementsByClassName('ant-layout-content')[0]
    },
  },
}
</script>
<style lang="scss" scoped>
#components-layout-demo-custom-trigger {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .logo-img {
    max-width: 60px;
    vertical-align: middle;
  }
  .logo {
    background-size: 56px auto;
    background-position: 37px center;
    background-repeat: no-repeat;
    font-size: 14px;
    color: #f3f5ff;
    text-align: center;
    padding: 20px 0;
  }
  .logo p {
    margin-top: 15px;
  }
  .menu-layout {
    overflow-y: auto;
  }
  // 收起展开菜单的class调用
  .ant-layout-sider-spread {
    flex: 0 0 50px !important;
    width: 200px !important;
    min-width: 200px !important;
  }
  .ant-layout-sider-inner {
    flex: 0 0 50px !important;
    width: 50px !important;
    min-width: 50px !important;
  }
  .ant-layout-header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: space-between;
    background: #fff;
  }
  .ant-layout-content {
    position: relative;
    overflow-y: auto;
  }
  // 非open端的logo
  .not-oem {
    display: flex;
    justify-content: center;
    .logo-img {
      width: 30px;
      align-self: flex-start;
    }
    h2 {
      font-size: 20px;
      margin-left: 10px;
      color: #f3f5ff;
    }
  }
  .ant-layout-sider-inner {
    .sider-menu-title {
      display: none;
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
  }
}
// 悬浮窗被loading覆盖
.ant-menu-submenu {
  z-index: 9999;
}
.ant-back-top {
  right: 20px;
  bottom: 20px;
  z-index: 20;
}
</style>
