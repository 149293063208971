var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"个人信息","footer":null},on:{"cancel":_vm.cancel},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"注册手机号","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"name":"mobilePhone"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mobilePhone']),expression:"['mobilePhone']"}],attrs:{"disabled":true,"autocomplete":"mobilePhone"}})],1),_c('a-form-item',{attrs:{"label":"个人姓名","required":true,"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"name":"userName"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'userName',
          {
            rules: [
              {
                required: true,
                message: '请输入个人姓名',
                trigger: 'blur'
              }
            ]
          }
        ]),expression:"[\n          'userName',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请输入个人姓名',\n                trigger: 'blur'\n              }\n            ]\n          }\n        ]"}],attrs:{"disabled":true,"type":"text"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }