<template>
  <a-breadcrumb v-if="showBreadCrumb">
    <a-breadcrumb-item
      v-for="route in currentRoutes"
      :key="route.action"
    >
      <a :href="`/micro-${route.action}`">
        {{ route ? route.title : '' }}
      </a>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentRoutes: [],
    }
  },
  computed: {
    ...mapState(['platformType', 'menus']),
    showBreadCrumb() {
      // console.log(this.platformType)
      // isShowBreadCrumbOnPath 放在路由参数里面定义是否显示面包屑，false：不显示，true：显示；不传默认显示
      const { query } = this.$route
      const notShowOntenantHome = this.$route.path.indexOf('/micro-tenant/dashboard') === -1
      const notShowOnproviderHome = this.$route.path.indexOf('/micro-provider-manage/dashboard') === -1
      return ((this.platformType === 'console-provider' && notShowOnproviderHome) || (this.platformType === 'console-tenant' && notShowOntenantHome)) && !(query.isShowBreadCrumbOnPath === 'false')
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route, prevRoute) {
        if (route && this.showBreadCrumb) {
          const { fullPath } = route
          // 去掉带的参数
          const currentPath = fullPath.replace('/micro-', '').split('?')[0]
          let routes = []
          this.menus.every((element) => {
            if (element.action === currentPath) {
              routes.push(element)
              return false
            }
            if (element.children && element.children.length) {
              routes = this.findPath(element, currentPath, routes, false)
            }
            return true
          })
          if (!routes.length) {
            const historyRoutes = window.sessionStorage.getItem('breadcrumbs')
            routes = JSON.parse(historyRoutes || '[]')
            const prevPath = prevRoute ? prevRoute.fullPath.replace('/micro-', '') : ''
            if (prevPath && routes[routes.length - 1]?.title && currentPath !== prevPath) {
              routes.push({
                action: currentPath,
                title: '',
              })
            }
          }
          this.currentRoutes = routes
          window.sessionStorage.setItem('breadcrumbs', JSON.stringify(routes))
        }
      },
    },
  },
  created() {
    const route = this.$route
    console.log(route, 'created')
    if (route) {
      const { fullPath } = route
      const currentPath = fullPath.replace('/micro-', '')
      let routes = []
      this.menus.every((element) => {
        if (element.action === currentPath) {
          routes.push(element)
          return false
        } if (element.children && element.children.length) {
          routes = this.findPath(element, currentPath, routes, false)
        }
        return true
      })
      this.currentRoutes = routes
      window.sessionStorage.setItem('breadcrumbs', JSON.stringify(routes))
    }
  },
  methods: {
    findPath(item, path, routes, canJump) {
      const { children } = item
      children.every((element) => {
        if (element.action === path) {
          routes.push({
            ...item,
            action: canJump ? item.action : path,
          })
          routes.push(element)
          return false
        } if (element.children && element.children.length) {
          routes = this.findPath(element, path, routes)
        }
        return true
      })
      return routes
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-breadcrumb {
  padding: 20px 0 0 20px;
}
</style>
