<template>
  <a-modal
    v-model="show"
    title="请填写信息"
    cancel-text="取消"
    ok-text="确认"
    @cancel="
      () => {
        form.resetFields()
      }
    "
    @ok="submitForm"
  >
    <a-form :form="form">
      <a-form-item
        label="原密码"
        :required="true"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        name="oldPassword"
      >
        <a-input
          :placeholder="isScrmPlatForm ? '请输入原密码' : ''"
          v-decorator="[
            'oldPassword',
            {
              rules: [
                {
                  required: true,
                  message: '请输入原密码',
                  trigger: 'blur'
                }
              ],
              initialValue: ''
            }
          ]"
          type="password"
          autocomplete="new-password"
        />
      </a-form-item>
      <a-form-item
        label="新密码"
        :required="true"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        name="newPassword"
      >
        <a-input
          :placeholder="isScrmPlatForm ? '请设置密码，长度至少为六位' : ''"
          v-decorator="[
            'newPassword',
            {
              rules: [
                {
                  required: true,
                  validator: newPasswordValid,
                  trigger: 'blur'
                }
              ]
            }
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item
        label="确认新密码"
        :required="true"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        name="confirmPwd"
      >
        <a-input :placeholder="isScrmPlatForm ? '请设置密码，长度至少为六位' : ''" v-decorator="['confirmPwd', newConfig]" type="password" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'inputForm' }),
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 12 },
      },
    }
  },
  computed: {
    ...mapState(['platformType']),
    isScrmPlatForm() {
      return this.platformType === 'console-scrm'
    },
    newConfig() {
      return {
        rules: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请再次输入新密码'))
              } else if (value !== this.form.getFieldValue('newPassword')) {
                callback(new Error(this.isScrmPlatForm ? '密码前后不一致，请核查修改' : '两次输入密码不一致'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      }
    },
    show: {
      get() {
        return this.showModal
      },
      set(val) {
        this.$emit('update:showModal', val)
      },
    },
  },
  methods: {
    newPasswordValid(rules, value, cb) {
      if (!value) return cb('请输入新密码')
      if (this.isScrmPlatForm && value.length < 6) return cb('登录密码不得少于六位，请重新设置')
      return cb()
    },
    submitForm() {
      this.form.validateFields((err, values) => {
        console.log(err)
        if (!err) {
          console.log('Received values of form: ', values)
          this.$post('/api/console-dlv/auth/reset-password', values).then(
            (res) => {
              if (res.code === 200) {
                this.$notification.success({ message: this.isScrmPlatForm ? '密码修改成功，请重新登录' : '修改成功' })
                this.show = false
                this.$emit('changePasswordSuccess')
              }
              this.form.resetFields()
            },
          )
        }
      })
    },
  },
}
</script>
