<template>
  <a-modal
    v-model="show"
    title="个人信息"
    :footer="null"
    @cancel="cancel"
  >
    <a-form :form="form">
      <a-form-item
        label="注册手机号"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        name="mobilePhone"
      >
        <a-input
          :disabled="true"
          v-decorator="['mobilePhone']"
          autocomplete="mobilePhone"
        />
      </a-form-item>
      <a-form-item
        label="个人姓名"
        :required="true"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        name="userName"
      >
        <a-input
          :disabled="true"
          v-decorator="[
            'userName',
            {
              rules: [
                {
                  required: true,
                  message: '请输入个人姓名',
                  trigger: 'blur'
                }
              ]
            }
          ]"
          type="text"
        />
      </a-form-item>
      <!-- <a-form-item
        label="身份证号"
        :required="true"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        name="idNumber"
      >
        <a-input :disabled="true" v-decorator="['idNumber', { rules: [{ required: true, message: '请输入个人身份证号', trigger: 'blur'}] }]" type="text" />
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>
<script>
export default {
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'personalMsg' }),
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 12 },
      },
    }
  },
  watch: {
    showModal(val) {
      if (val) {
        this.getPersonalMsg()
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.showModal
      },
      set(val) {
        this.$emit('update:showModal', val)
      },
    },
  },
  methods: {
    cancel() {
      this.show = false
    },
    async getPersonalMsg() {
      const mobilePhone = this.form.getFieldValue('mobilePhone')
      if (mobilePhone) return
      const { data } = await this.$get('/api/scrm/common/user-info') || { data: {} }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          userName: data.userName || '',
          // idNumber: data.idNumber || '',
          mobilePhone: data.mobilePhone || '',
        })
      })
    },
  },
}
</script>
