<template>
  <a-sub-menu :key="route.action || route.menuId" v-bind="$props" v-on="$listeners">
    <span
      slot="title"
      :class="`submenu-title-wrapper ${theme}`"
      :key="route.action || route.menuId"
    >
      <menu-icon :icon="route.icon" />
      <a class="sider-menu-title" href="javascript:;"> {{ route.title }}</a>
      <!-- 这里需要显示客户代办，只有合同审批（客户申请，渠道申请），充值调账（充值管理，退款），发票管理（发票工具）
        当菜单合起来后显示，展开不显示
       -->
      <transition name="fade">
          <a-badge
            v-if="statisticsEnum[route.action]"
            :count="count"
          />
      </transition>
    </span>
    <template v-for="item in route.children">
      <a-menu-item
        :class="`pop-${theme}`"
        v-if="!item.children || !item.children.length"
        :key="item.action || item.menuId"
      >
        <a class="sider-menu-title" @click="refreshStop" :href="`/micro-${item.action}`">
          {{ item.title }}</a
        >
        <!-- 这里需要显示子菜单的代办数 -->
        <a-badge
          v-if="statisticsEnum[item.action]"
          :count="menusStatistics[statisticsEnum[item.action]]"
        />
      </a-menu-item>
      <sub-menu
        v-else
        :route="item"
        :key="item.action || item.menuId"
        :statisticsEnum="statisticsEnum"
        :openKeys="openKeys"
        :menusStatistics="menusStatistics"
      ></sub-menu>
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { mapState } from 'vuex'
import platformEnum from '@/utils/platformEnum'
import menuIcon from './menuIcon.vue'

export default {
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  components: {
    menuIcon,
  },
  props: {
    ...Menu.SubMenu.props,
    route: {
      type: Object,
    },
    statisticsEnum: {
      type: Object,
    },
    menusStatistics: {
      type: Object,
    },
    openKeys: {
      type: Array,
    },
  },
  computed: {
    ...mapState(['platformType']),
    theme() {
      return this.platformType
        ? platformEnum[this.platformType].theme || 'dark'
        : 'dark'
    },
    count() {
      let num = 0
      if (Array.isArray(this.statisticsEnum[this.route.action])) {
        this.statisticsEnum[this.route.action].forEach((key) => {
          num += Number(this.menusStatistics[key] || 0)
        })
      } else {
        num = this.menusStatistics[this.statisticsEnum[this.route.action]]
      }
      return num
    },
  },
  data() {
    return {
      // 菜单key的对应代办数key
    }
  },
  methods: {
    refreshStop(e) {
      e.preventDefault()
    },
  },
}
</script>

<style lang="scss" scoped>
.submenu-title-wrapper,
.ant-menu-item {
  /deep/ .ant-badge-count {
    box-shadow: none;
    margin-left: 6px;
    height: 16px;
    line-height: 16px;
    margin-top: -4px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sider-menu-title {
  margin-left: 5px;
  display: inline;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.65);
}
// light主题下的
.light {
  .sider-menu-title {
    color: $grey;
  }
}
// 仿原生高亮
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  .sider-menu-title {
    color: #1890ff;
  }
  .svg-icon {
    color: #1890ff;
  }
}
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  .sider-menu-title {
    color: #fff;
  }
  .svg-icon {
    color: #fff;
  }
}
.ant-menu-light .pop-light {
  &.ant-menu-item-selected,
  &.ant-menu-item-selected:hover {
    background: rgba(32, 135, 255, 1);
    .sider-menu-title {
      color: #fff;
    }
  }
  .sider-menu-title {
    color: $grey;
  }
}
.ant-menu-light .pop-light:hover {
  .sider-menu-title {
    color: #1890ff;
  }
}
.ant-menu-light {
  .ant-menu-submenu-selected,
  .ant-menu-submenu-selected:hover {
    background: rgba(32, 135, 255, 1);
    .sider-menu-title {
      color: #fff !important;
    }
    .svg-icon {
      color: #fff !important;
    }
    /deep/ .ant-menu-submenu-arrow::before,
    /deep/ .ant-menu-submenu-arrow::after {
      background: #fff;
    }
  }
}
</style>
