var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"请填写信息","cancel-text":"取消","ok-text":"确认"},on:{"cancel":function () {
      _vm.form.resetFields()
    },"ok":_vm.submitForm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"原密码","required":true,"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"name":"oldPassword"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'oldPassword',
          {
            rules: [
              {
                required: true,
                message: '请输入原密码',
                trigger: 'blur'
              }
            ],
            initialValue: ''
          }
        ]),expression:"[\n          'oldPassword',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请输入原密码',\n                trigger: 'blur'\n              }\n            ],\n            initialValue: ''\n          }\n        ]"}],attrs:{"placeholder":_vm.isScrmPlatForm ? '请输入原密码' : '',"type":"password","autocomplete":"new-password"}})],1),_c('a-form-item',{attrs:{"label":"新密码","required":true,"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"name":"newPassword"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newPassword',
          {
            rules: [
              {
                required: true,
                validator: _vm.newPasswordValid,
                trigger: 'blur'
              }
            ]
          }
        ]),expression:"[\n          'newPassword',\n          {\n            rules: [\n              {\n                required: true,\n                validator: newPasswordValid,\n                trigger: 'blur'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":_vm.isScrmPlatForm ? '请设置密码，长度至少为六位' : '',"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"确认新密码","required":true,"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"name":"confirmPwd"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['confirmPwd', _vm.newConfig]),expression:"['confirmPwd', newConfig]"}],attrs:{"placeholder":_vm.isScrmPlatForm ? '请设置密码，长度至少为六位' : '',"type":"password"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }